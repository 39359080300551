import styled, { css, keyframes  } from 'styled-components';

import { Link } from 'react-router-dom';
import { DatePicker } from 'antd';

import wslogo from '../../assets/images/wslogo.png';
import flogo from '../../assets/images/flogo.png';
import inlogo from '../../assets/images/inlogo.png';

import backgroundImage from '../../assets/images/background.png';

const FooterContentPlural = css`
    flex-direction: column;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`;

const FooterContentSingular = css`
    justify-content: center;
`;


export const FooterElement = styled.div`
    display: flex;
    height: auto;
    background: linear-gradient(to bottom, black, #722440);
    ${(props) => (
            props.plural === "1"
            ? FooterContentPlural
            : FooterContentSingular
        )
    }
`;

export const DividerLineInFooter = styled.div`
    width: 95%;
    height: 2px;
    border-radius: 20px;
    background-color: white;
    /*margin-top: -8px;*/
`;

export const ContainerNavBarInFooter = styled.div`

    &:nth-child(1){
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    &:nth-child(2){
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    &:nth-child(3){
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    &:nth-child(4){
        margin:20px 0px 20px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
`;

export const NavBarItem  = styled.div`
    margin: 0px 10px 0px 10px;
    font-family: "Zen Dots", sans-serif; 
    color: red !important;
    letter-spacing: 1px;
    font-weight: 550;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledLink = styled(Link)`
    color: rgba(245, 245, 245, 0.575);  
    transition: color ease-in-out 250ms;

    &:hover {
        color: whitesmoke; 
    }
`;

export const ContainerSocialNetworks = styled.div`
    width: 100px;
    display: flex;
    justify-content: space-between;
`;

export const SocialNetworks = styled.div`
    cursor: pointer;
    transition: all ease-in-out 250ms;

    &:hover{
        filter: brightness(80%);
    }

    &:nth-child(1) > div{
        padding: 10px;
        background: url(${wslogo}) no-repeat center;
        background-size: cover;
    }

    &:nth-child(2) > div{
        padding: 10px;
        background: url(${flogo}) no-repeat center;
        background-size: cover;
    }

    &:nth-child(3) > div{
        padding: 10px;
        background: url(${inlogo}) no-repeat center;
        background-size: cover;
    }

`;

export const ContainerArticle = styled.article`
    height: auto;
    background-image: url(${backgroundImage});
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100vmax 100vmax;

    background-color: white;
`;



export const ContainerArticleForReservations = styled.article`
    height: auto;
    background: linear-gradient(to top, black, #722440);
`;


export const ContainerFood = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: "Monserrat", sans-serif;
`;

export const ContainerFoodChild = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-items: center; /* Alineación vertical al centro */
`;

export const ContainerFoodImage = styled.div`
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    color: white;
    width: 100%;
    max-width: 400px;

    height: 300px;

    @media (max-width: 685px) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media (min-width: 686px) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;

export const FoodImage = styled.img`
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
`;

export const ContainerFoodText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    height: 300px; /* Altura fija */
    border-bottom: 2px solid black;
    padding-bottom: 20px;

    @media (max-width: 685px) {
        max-width: 400px;
        flex: 1;
    }

    @media (min-width: 686px) {
        max-width: 500px;
        flex: 1;
    }
`;

export const FoodTitle = styled.span`
    color: #722440;
    font-weight: 540;

    @media (max-width: 934px) {
        font-size: 1.1rem;
    }

    @media (min-width: 934px) {
        font-size: 1.5rem;
    }

    @media (max-width: 685px) {
        margin: 20px 0px 0px 0px;
    }

    @media (min-width: 686px) and (max-width: 738px) {
        margin: 10px 0px 0px 0px;
    }

    @media (min-width: 739px) and (max-width: 1100px) {
        margin: 30px 0px 0px 0px;
    }

    @media (min-width: 1101px){
        margin: 60px 0px 0px 0px;
    }
`;

export const FoodPrice = styled.span`
    font-weight: 550;
    color: #722440;
    margin-top: 10px;
`;

export const FoodDescription = styled.p`
    color: black;
    word-wrap: break-word;
    margin: 20px 10px 0px 10px;
`;

const fadeInOut = css`
    opacity: 0;
    animation: fade 1s ease-in-out forwards;
`;

export const StyleImageCorousel = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: grayscale(0.6);
`;

export const ContainerCalendar = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 823px) {
        padding: 0px 10px 20px 10px;
    }

    @media (min-width: 823px) {
        padding: 0px 10px 40px 10px;
    }

`;

export const SpanShowMoreInCalendar = styled.span`
    color: white;
    font-size: 0.7rem;
`;

export const ToolbarButton = styled.button`
    background-color: transparent;
    font-family: "Zen Dots", sans-serif;
    color: #fff !important;
    padding: 8px 16px !important;
    cursor: pointer !important;
    transition: all ease-in-out 250ms;

    &:hover {
        background: black !important;
    }
    &:focus {
        background: black !important;
    }

    &:nth-child(1) {
        border: 1px solid whitesmoke !important;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &:nth-child(2) {
        border-top: 1px solid whitesmoke !important;
        border-left: none !important;
        border-bottom: 1px solid whitesmoke !important;
        border-right: none !important;
        border-radius: 0px !important;
        margin: 0px !important;
    }

    &:nth-child(3) {
        border: 1px solid whitesmoke !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

export const InfoForClickReservationDay = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`; 

export const IconInfoForClickReservationDay = styled.img`
    transform:scale(0.4);
`; 

export const SpanInfoForClickReservationDay = styled.span`
    font-family: "Zen Dots", sans-serif;
    color: white;
    letter-spacing: 1px;

    @media (max-width: 934px) {
        font-size: 0.66rem;
    }

    @media (min-width: 934px) {
        font-size: 0.8rem;
    }
`; 

//Stylos para formularios de inicio de secion y de registro

export const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    background: linear-gradient(to top, black, #722440) ! important;
    font-family: "Zen Dots", sans-serif;
    padding-bottom: 200px;
`; 

export const ContainerFormObject = styled.form`
    
    height: 100%;
    background-color: whitesmoke;
    box-shadow: rgb(159 162 191 / 18%) 0px 2px 3px, rgb(159 162 191 / 32%) 0px 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 8px;

    @media (max-width: 823px) {
        width: 90%;
    }

    @media (min-width: 823px) {
        width: 50%;
    }
`;

export const ContainerFormObjectChild = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-child(1) {
        width: 80%;
    }
    &:nth-child(2) {
        width: 80%;
    }
    &:nth-child(3) {
        width: 85%;
    }
    &:nth-child(4) {
        width: 90%;
    }
`;

export const ContainerFormObjectChildCircle = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(to top, black, #722440);
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContainerFormObjectChildCircleWithImage = styled.img`
    width: 100%;
    height: 100%;
    transform:scale(0.72);
`;

export const ContainerFormObjectChildCircleSvg =  styled.svg`
    fill: white;
    margin-top: 20%;
    width: 60%;
    height: 60%;
`;

export const ContainerFormObjectChildText =  styled.span`
    margin: 30px 0px 40px 0px;
    width: 100%;
    color: #722440;
    font-family: "Zen Dots", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    text-align: justify;
    text-decoration: none;
    text-align: center;
`;

export const ContainerFormObjectChildContainerPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: -10px;
    margin-left: -20px; 
    font-family: "Zen Dots", sans-serif;
`; 

export const ContainerFormObjectChildPlaceholder = styled.div`
    color: #722440;
    transition: ease-in-out 200ms;
    border-radius: 4px;
`; 

export const ContainerFormObjectChildInput = styled.input`
    margin-top: 20px;
    outline: none;
    width: 100%;
    height: 50px;
    background-color: transparent;
    border:  1px solid rgb(230, 240, 255);
    border-radius: 4px;
    color: black;
    padding-left: 20px; 
    font-family: "Zen Dots", sans-serif;

    &:hover {
        border:  1px solid #722440;
        transition: ease-in-out 200ms;
    }
    &:focus{
        border:  1px solid #7224417b;
        transition: ease-in-out 200ms;
    }
`;

export const ContainerFormObjectChildInputDatePicker = styled(DatePicker)`
    margin-top: 20px;
    outline: none;
    width: 105%;
    height: 50px;
    background-color: transparent;
    border:  1px solid rgb(230, 240, 255);
    border-radius: 4px;
    color: black;
    padding-left: 20px; 

    &:hover {
        border:  1px solid #722440;
        transition: ease-in-out 200ms;
    }
`;

export const ContainerFormObjectChildButton =  styled.button`
    margin-top: 20px;
    outline: none;
    width: 100%;
    height: 38px;
    background: linear-gradient(to top, black, #722440);
    border:  none;
    border-radius: 4px;
    color: white;
    font-family: "Monserrat", sans-serif;
    transition: ease-in-out 200ms;
    letter-spacing: 1px;
    opacity: 0.8;

    &:hover {
        transition: ease-in-out 200ms;
        opacity: 1;
    }
`;

//styles for the reservations
export const ContainerFormObjectReservation = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: black !important;
`; 

//styles for the Alert Message

const AlertMesageSuccess = css`
    background-color: #7224416a;
    border: 1px solid #722440;
    color: white;
`;

const AlertMesageWarning = css`
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
`;

const AlertMesageError = css`
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
`;

const growAnimationForContainerAlertMesage = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: 80px;
    }
`;


export const ContainerAlertMesage = styled.div`
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    border-radius: 8px;
    animation: ${growAnimationForContainerAlertMesage} 330ms ease-in-out forwards;

    @media (max-width: 823px) {
        width: 90%;
    }

    @media (min-width: 823px) {
        width: 50%;
    }
    
    ${(props) => (
            props.option_alert_message === "success"
            ? AlertMesageSuccess
            : props.option_alert_message === "warning"
            ? AlertMesageWarning
            : props.option_alert_message === "error"
            ? AlertMesageError
            : ""
        )
    }

    ${(props) => (
        props.option_alert_message === "success"
        ? "margin: 0px 0px 40px 0px;"
        : ""
    )
}
`; 

export const AlertMesage = styled.div`
    flex: 1;
    display: flex;
`; 


export const ContainerIconAlertMessage = styled.div`
    width: 40px;
    height: 40px;
    margin: 0px 20px 0px 20px;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    border-radius: 50%;
    cursor: pointer;
    ${(props) => (
            props.option_alert_message === "success"
            ? AlertMesageSuccess
            : props.option_alert_message === "warning"
            ? AlertMesageWarning
            : props.option_alert_message === "error"
            ? AlertMesageError
            : ""
        )
    }
`; 

export const ContainerFatherTextAlertMessage = styled.div`
    flex: 1;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`; 

export const ContainerTextAlertMessage = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alineación vertical al centro */
`; 

export const TextAlertMessage = styled.span`
    font-family: "Zen Dots", sans-serif;
    font-weight: 400;

    &:nth-child(2) {
        margin: 5px 0px 0px 0px;
    }

    @media (max-width: 934px) {
        font-size: 0.8rem;
    }

    @media (min-width: 934px) {
        font-size: 1rem;
    }
`; 



//Vinos
export const ContainerWines = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: "Monserrat", sans-serif;
    
    @media (max-width: 934px) {
        font-size: 0.8rem;
    }

    @media (min-width: 934px) {
        font-size: 1rem;
    }
`; 

export const ContainerWineChild = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-items: center; /* Alineación vertical al centro */
`;

export const ContainerWineImage = styled.div`
    background: linear-gradient(to top, black, #722440);
    flex: 1:
    overflow: hidden; /* Asegura que la imagen no sobresalga del contenedor */
    padding: 20px 0px 20px 0px;
    cursor: pointer;
`;

export const FlagImage = styled.img`
    width: 40px;
    object-fit: cover;
    object-position: bottom;
`;

export const WineImage = styled.img`  
    object-fit: cover;
    object-position: bottom;
    
    max-width: 450px;
    max-height: 450px;
    width: 100%;
    height: 100%;
`;

export const ContainerWineChildTextTheWineAndName = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0px 0px 0px;
`;

export const ContainerWineChildTextTheWine = styled.div`
    display: flex;
    margin: 10px 0px 0px 0px;
`;

export const ContainerWineText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid black;

    @media (max-width: 685px) {
        width: 100%;
        max-width: 450px;
        height: auto;
        padding-bottom: 20px;
    }

    @media (min-width: 686px) {
        width: 80%;
    }
`;

export const ContainerWineTextChildForNameAndFlag = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #722440;

    @media (max-width: 934px) {
        font-size: 1.1rem;
    }

    @media (min-width: 934px) {
        font-size: 1.5rem;
    }
`;


export const ContainerWineTextChild = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    text-align: justify;

    @media (max-width: 685px) {
        width: 100%;
        height: auto;
        max-width: 300px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 20px;
    }

    @media (min-width: 686px) {
        width: 100%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;



export const ContainerCircleForElementTheWine= styled.div`
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`;

export const CircleForElementTheWine = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 20px 0px 20px;
    background-color: #722440;
`;


export const WineParameterNameBold = styled.span`
    font-weight: bold;
`;

export const WineTitle = styled.span`
    font-weight: 400;
    margin: 0px;
`;

export const WinePrice = styled.span`
    @media (max-width: 685px) {
        margin: 10px 0px 0px 0px;
    }

    @media (min-width: 686px) and (max-width: 738px) {
        margin: 10px 0px 0px 0px;
    }

    @media (min-width: 739px) and (max-width: 1100px) {
        margin: 20px 0px 0px 0px;
    }

    @media (min-width: 1101px){
        margin:20px 0px 0px 0px;
    }
`;

export const WineDescriptionChild = styled.p`
    word-wrap: break-word;

    @media (max-width: 685px) {
        margin: 20px 10px 10px 10px;
    }

    @media (min-width: 686px) and (max-width: 738px) {
        margin: 10px 10px 0px 10px;
    }

    @media (min-width: 739px) {
        margin: 10px 10px 0px 10px;
    }
`;

export const ContainerButtomForViewMoreAndLess = styled.div`
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    padding: 10px 0px;
    -webkit-box-shadow: 0px 5px 10px -8px rgba(0,0,0,0.58);
    -moz-box-shadow: 0px 5px 10px -8px rgba(0,0,0,0.58);
    box-shadow: 0px 5px 10px -8px rgba(0,0,0,0.58);
`;
export const ModalHeaderText = styled.span`
    padding-left: 20px;
    flex: 1;
    text-align: justify;
    color: #722440;

    @media (max-width: 934px) {
        font-size: 1.1rem;
    }

    @media (min-width: 934px) {
        font-size: 1.5rem;
    }
`;

export const ModalContent = styled.div`
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: 100%;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar:vertical {
        width: 10px;
    }
    
    &:hover::-webkit-scrollbar {
        display: initial;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(to top, black, #722440);
        border-radius: 4px;
    }
`;

export const CloseModalButtom =  styled.button`
    margin-right:20px;
    padding: 0px;
    outline: none;
    border: none;
    color: #722440;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    transition: ease-in-out 200ms;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
    &:focus {
        opacity: 1;
    }
`;

export const ContainerSearchInput =  styled.div`
    margin: 0 auto;    
    width: 100%;

    @media (max-width: 685px) {
        max-width: 300px;
    }

    @media (min-width: 686px) {
        max-width: 450px;
    }
`;

export const SearchInput = styled.input`
    margin-top:20px;
    outline: none;
    width: 100%;
    height: 50px;
    
    border-top: 0px;
    border-left: 0px;
    border-bottom: 2px solid white;
    border-right: 0px;

    border-radius: 4px;
    color: white;
    font-family: "Zen Dots", sans-serif;
    background: linear-gradient(to top, black, #722440);
    text-indent: 20px; /* Ajusta el valor según sea necesario */
    padding: 0px;
`;


export const ContainerFilteredOptionsForSearch =  styled.div`
    margin: 0 auto;    
    width: 100%;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    border-radius: 4px;
    color: white;
    font-family: "Zen Dots", sans-serif;
    font-size: 0.7rem;
    background: linear-gradient(to top, black, #722440);
    letter-spacing: 2px;
    
    @media (max-width: 685px) {
        max-width: 300px;
    }

    @media (min-width: 686px) {
        max-width: 450px;
    }
`;

export const FilteredOptionsForSearch =  styled.div`
    margin-top: -10px;

    padding-top: 15px;
    padding-bottom: 5px;
    flex: 1;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    cursor: pointer;
    transition: ease-in-out 200ms;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }
    &:focus {
        opacity: 1;
    }
`;

//Stylos for 

export const ContainerDistilledText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 300px; /* Altura fija */
    border-bottom: 2px solid black;
    padding-bottom: 20px;

    @media (max-width: 685px) {
        max-width: 700px;
        flex: 1;
    }

    @media (min-width: 686px) {
        max-width: 700px;
        flex: 1;
    }
`;


export const ContainerChildDistilledText = styled.div`
    margin: 0px 0px 20px 0px;
`;

export const DistilledTitle = styled.span`
    color: #722440;
    text-align: center;
    margin: 0px 0px 25px 0px;

    @media (max-width: 934px) {
        font-size: 1.1rem;
    }

    @media (min-width: 934px) {
        font-size: 1.5rem;
    }
`;

export const ContainerCircleForElementTheDistilled = styled.div`
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`;

export const CircleForElementTheDistilled = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 0px 0px 0px;
    background-color: #722440;
`;

export const DistilledDescription = styled.p`
    width: 80%;
    color: black;
    word-wrap: break-word;
    margin: 0px 10px 0px 10px;
    text-align: justify;


    @media (max-width: 934px) {
        font-size: 0.9rem;
    }

    @media (min-width: 934px) {
        font-size: 1rem;
    }
`;

export const SpanForListItemsNull = styled.span`
    font-family: "Zen Dots", sans-serif;
    font-weight: 550;
    letter-spacing: 1px;
    color: #722440;
    text-align: center;

    @media (max-width: 934px) {
        font-size: 0.9rem;
    }

    @media (min-width: 934px) {
        font-size: 1rem;
    }
`;


//Contact info
export const ContainerContactInfoImage = styled.div`
    flex: 1:
    overflow: hidden; /* Asegura que la imagen no sobresalga del contenedor */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
`;

export const ContactInfoImage = styled.img`  
    object-fit: cover;
    object-position: bottom;
    width: 94%;
    height: 92%;
    filter: sepia(30%);
`;

export const ContactInfoContainerTextInImage = styled.div`
    position: absolute;
    width: 94%;
    height: 92%;
    max-width: 400px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Alineación horizontal al centro */
`;

export const SpanViewHome1 = styled(Link)`
    transition: all ease-in-out 100ms;
    font-family: 'font1', sans-serif;
    color: #722440;
    letter-spacing: 1px;
    font-weight:600;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    
    @media only screen and (min-width: 0px) and (max-width: 830px){
        font-size: 4rem;    
    }
        
        
    @media only screen and (min-width: 831px){
        font-size: 6rem;
    }
`;

export const SpanViewHome2 = styled.span`    
    transition: all ease-in-out 500ms;
    font-family: "Zen Dots", sans-serif; 
    color: whitesmoke;
    letter-spacing: 1px;
    font-weight:300;
    text-align: center;
    font-size: 1.5rem;
    text-shadow:1px 1px 0 #000,-1px -1px 0 #000,  1px -1px 0 #000,-1px 1px 0 #000, 1px 1px 0 #000;
`;

export const ContainerContactInfoText = styled.div`
    flex: 1:
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; /* Alineación vertical al centro */
`;

export const ContainerContactInfoTextChild = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 40px;
`;

export const ContactInfoTextChild1 = styled.span`
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
`;

export const ContactInfoTextChild2 = styled.span`
    font-size: 0.9rem;
    font-weight: 450;
    letter-spacing: 2px;
`;


//cartas_menu_styled

export const ContainerArticleForElementsMenu = styled.article`

    background-image: url(${backgroundImage});
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100vmax 100vmax;
    background-color: white;

    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -4;
    overflow-y: scroll;
`;

export const ContainerLogo = styled.div`
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const LogoImage = styled.img`
    object-fit: cover;
    object-position: bottom;
    transform: scale(0.7);

    opacity: 0.1;
`;

export const ContainerElementsMenu = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-family: "Monserrat", sans-serif;
`;

export const ContainerElementMenuChild = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: Column;
    align-items: center; /* Alineación vertical al centro */
`;

export const ContainerElementMenuImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    color: white;
    width: 100%;
    max-width: 800px;
    

    @media (max-width: 685px) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media (min-width: 686px) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    ${(props) => (
        props.height === "1"
        ? "height: 400px; min-height: 400px; max-height: 400px;"
        : "height: auto;"
    )
}
`;

export const ElementMenuImage = styled.img`
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
`;

export const ContainerElementMenuText = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: auto; /* Altura fija */
    border-bottom: 3px solid black;
    padding: 10px 0px 10px 0px;

    max-width: 500px;

`;

export const ElementMenuTitle = styled.span`
    flex: 1;

    color: #722440;
    font-weight: 550;
    margin: 0px 0px 10px 0px;

    @media (max-width: 934px) {
        font-size: 1.1rem;
    }

    @media (min-width: 934px) {
        font-size: 1.5rem;
    }
`;

export const ContainerElementMenuDescription = styled.div`
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0px 0px 0px 10px;
`;

export const ElementMenuDescription = styled.p`
    font-size: 1.1rem;
    font-weight: 540;
    color: black;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ContainerElementMenuPrice = styled.div`
    width: 30%;

    display: flex;
    align-items: center;
    justify-content: center;
`;


export const ElementMenuPrice = styled.span`
    font-weight: 550;
    color: #722440;

    @media (max-width: 934px) {
        font-size: 1.1rem;
    }

    @media (min-width: 934px) {
        font-size: 1.5rem;
    }
`;


//vinos_with_price

export const ContainerSearchInputAndSelectedOptions = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;

    background-image: url(${backgroundImage});
    background-position: center;
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100vmax 100vmax;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 95.5px;
    padding: 0px 0px 20px 0px;
`;

export const ContainerWineWithPriceChild = styled.div`
    display: flex;
    margin: 20px 20px 40px 20px;
    align-items: center; /* Alineación vertical al centro */
    border-bottom: 2px solid black;
`;

export const ContainerWineWithPriceParameterNameBold = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;

    /*background-color: red;*/
`;

export const ContainerWineWithPriceText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0px;

    @media (max-width: 685px) {
        width: 100%;
        max-width: 450px;
        height: auto;
        padding-bottom: 20px;
    }

    @media (min-width: 686px) {
        width: 80%;
    }
`;

export const ContainerFilteredOptionsLevel2ForSearch =  styled.div`
    margin: 0 auto;  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Alineación vertical al centro */
    border-radius: 4px;
    color: white;
    font-family: "Zen Dots", sans-serif;
    font-size: 0.7rem;
    background: linear-gradient(to top, black, #722440);
    letter-spacing: 2px;
    
    @media (max-width: 685px) {
        max-width: 300px;
    }

    @media (min-width: 686px) {
        max-width: 450px;
    }
`;

export const FilteredOptionLevel2ForSearch = styled.div`
    padding: 10px;
    width: 100%;
    border: 2px solid white;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    @media (max-width: 685px) {
        max-width: 280px;
    }

    @media (min-width: 686px) {
        max-width: 430px;
    }
`;

export const FlagImageForSeletedOpcionInInputSearch = styled.img`
    width: 30px;
    object-fit: cover;
    object-position: bottom;

    margin: 0px 10px 0px 0px;
`;

/*styles for new menú*/
const ButtomMenuAbsoluteAnimation = keyframes`
    0% { transform: rotate(72deg); }
    20% { transform: rotate(144deg); }
    40% { transform: rotate(216deg); }
    60% { transform: rotate(288deg); }
    80% { transform: rotate(360deg); }
    100% { transform: rotate(72deg); }
`

export const ButtomMenuAbsolute = styled.div`
    font-family: "Zen Dots", sans-serif;
    user-select: none;
    position: absolute;
    top: 20px;
    right: 20px;

    z-index: 1001;
    background-color: black;
    color: white;

    padding: 10px 50px;
    display: flex;
    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    border-radius: 20px;
    cursor: pointer;
    transition: ease-in-out 500ms;

    &:hover {
        transform: rotate(360deg);
    }
`; 

export const ContainerMenuAbsolute = styled.div`
    font-family: "Zen Dots", sans-serif;
    position: absolute;
    
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1000;

    width: 550px;

    justify-content: center; /* Alineación horizontal al centro */
    align-items: center; /* Alineación vertical al centro */
    
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    background-color: black;
    opacity: 0.97;

        
    @media (max-width: 685px) {
        max-width: 100%;
    }
    
    ${(props) => (
            props.open_or_close === "1"
            ? "display: flex;"
            : "display: none;"
        )
    }
`;

export const ContainerChildHeaderMenuAbsolute = styled.div`
    position: absolute;
    top: 35px;
    left: 40px;

    display: flex;
    font-size: 0.8rem;
    color: white;

    @media (max-width: 460px) {
        top: 70px;
        right: 40px;
    }
`;

export const ContainerChildMenuAbsolute = styled.div`
    position: absolute;
    top: 100px;
    left: 40px;
    right: 40px;

    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
`;

export const ChildMenuAbsolute = styled(Link)`
    color: white;
    border-bottom: 2px solid white;

    padding: 15px 0px; 
    cursor: pointer;

    &:hover {
        color: #722440;
        border-bottom: 2px solid #722440;
    }

    &:focus {
        color: #722440;
        border-bottom: 2px solid #722440;
    }
`;